import React from "react";
import {
  MainContainer,
  Container,
  BgImage,
  DetailsSection,
  Heading,
} from "./HaloSection.styles";
import Navigations from "./Navigations.controller";
import { Text } from "@/outscal-commons-frontend/Styled/TextStyles";
import dynamic from "next/dynamic";

const LeadsForm  = dynamic(() => import("../LeadsForm/LeadsForm.controller"));

const HaloSectionView = ({
  id,
  data,
  heading,
  subHeading,
  links,
  bgImage,
  paidCoursesIdAndLabels,
  hasBgImage,
  formGap
}) => {
  return (
    <MainContainer>
      {bgImage && (
        <BgImage
          fill
          style={{ objectFit: "cover" }}
          src={bgImage}
          alt={heading}
          quality={100}
          priority 
        />
      )}
      <Container gap="16px" alignItems="center" justifyContent="space-between">
        <DetailsSection
          alignItems="flex-start"
          gap="12px"
          hasBgImage={hasBgImage}
        >
          <Navigations links={links} />
          <Heading>{heading}</Heading>
          <Text>{subHeading}</Text>
        </DetailsSection>
        <LeadsForm paidCoursesIdAndLabels={paidCoursesIdAndLabels} formGap={formGap} />
      </Container>
    </MainContainer>
  );
};

export default HaloSectionView;
